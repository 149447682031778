export default {
	maintenance: {
		title: 'Servicios en mantenimiento',
		subTitle1:
			'11/03 - 16H : <br/><br/>\
      Debido a un incendio en nuestra host OVH, nuestros servicios están actualmente interrumpidos.<br/><br/>\
      OVH debe realizar un ciertas reparaciones, incluyendo la red eléctrica, que se planificarán a partir de la próxima semana.<br/>\
      Caja registradora -> No se ve afectada<br/><br/>\
      Informes, acceso a datos -> Servicio interrumpido, no accesible<br/>\
      Click & Collect, servicio de mediación -> Servicio interrumpido, no accesible<br/><br/>\
      Como recordatorio, puede acceder al historial de sus servicios desde su caja registradora:<br/><br/>\
      Configuración -> Histórico de la caja.<br/><br/>\
      Gracias por su paciencia e indulgencia.<br/>\
      El equipo de L’Addition',
		subTitle2: ' ',
		altMaintenance: 'mantenimiento',
	},
	tooltip: {
		emailEquals: {
			important: 'Importante',
			identical: 'Este email es idéntico al anterior',
		},
		emailHint: {
			important: 'Importante',
			inputEmail:
				'Introduce un email válido al que tengas acceso (un email de verificación se enviará a esta dirección)',
			notAssociated: "Este email no está asociado a ninguna cuenta L'Addition",
		},
		passwordEquals: {
			important: 'Importante',
			identical: 'Esta contraseña es idéntica a la anterior',
		},
		passwordHint: {
			title: 'Tu contraseña debe incluir:',
			nbCharacters: 'al menos 8 caracteres',
			uppercase: 'al menos 1 mayúscula',
			lowercase: 'al menos 1 minúscula',
			numerical: 'al menos 1 cifra',
			special: 'al menos 1 símbolo',
		},
	},
	header: {
		nameTool: 'Autent',
		nameCompany: "L'Addition",
	},
	errorCode: {
		NOT_SENDING_MAIL: 'Error en enviar el correu electrònic de verificació',
		UNKNOWN_USER: 'Aquest email no existeix',
		ACCOUNT_NOT_CONFIRMED: 'El vostre compte no ha estat validat',
		UNKNOWN_TOKEN: 'Enllaç no vàlid, torna-ho a provar.',
		TOKEN_EXPIRATION: 'L\'enllaç ha caducat, torna a provar l\'operació.',
		UNKNOWN_ERROR: 'S\'ha produït un error',
		ACCOUNT_NOT_READY: 'El vostre compte encara no està preparat, espereu que el vostre compte sigui validat per correu electrònic.',
	},
	errorLocalStore:
		'Se ha producido un error durante la autenticación. Por favor, cierra el modo navegación de incógnito o cambia de navegador.',
	errorUnknown: 'Se ha producido un error desconocido, por favor inténtalo de nuevo.',
	wrongService: "Este servicio no pertenece a L'Addition.",
	notAllowed: 'No tienes acceso a este servicio',
	notAllowedPage: 'No tienes acceso a esta página',
	needLogin: 'Necesitas iniciar sesión para acceder a esta página.',
	loginForm: {
		errorIdentifierEmpty: 'Campo requerido',
		errorPasswordEmpty: 'Campo requerido',
		invalidCredentials: 'Usuario o contraseña incorrectos.',
		tooManyWrongRequest:
			'Cuenta bloqueada. Por favor, ponte en contacto con el soporte técnico.',
		loginChanged:
			'Por favor, si has modificado tus datos recientemente, no utilices los anteriores para iniciar sesión en @laddition.com',
		title: 'Autenticación',
		description: 'Identificarme en ',
		emailLabel: 'Usuario/Email',
		passwordLabel: 'Contraseña',
		connection: 'Iniciar sesión',
		forgotPassword: '¿Has olvidado tu contraseña?',
		sponsorship: 'Apadrina a un amigo y recibe 100 €',
		altSponsorship: 'regalo patrocinio',
		successAuthentication: 'Autenticación completada',
	},
	logout: {
		title: 'Cerrando sesión...',
		error: 'Se ha producido un error durante el cierre',
		button: 'Cerrar sesión',
	},
	resetPassword1: {
		empty: 'Campo requerido',
		successVerification:
			'Casi has acabado. En breves instantes, recibirás un email de verificación. Para modificar tu contraseña, haz clic en el botón del email.',
		successSupport:
			'El soporte técnico procesará tu solicitud lo antes posible.',
		notConfirmed: 'Tu cuenta no ha sido validada.',
		noUser: 'Este email no existe.',
		error: 'Se ha producido un error.',
		title: 'Cambia tu contraseña',
		emailLabel: 'Usuario/Email',
		submit: 'Validar',
	},
	resetPassword2: {
		empty: 'Campo requerido',
		errorValidator:
			'Tu contraseña debe contener, al menos, 8 caracteres, 1 mayúscula, 1 minúscula, 1 cifra y 1 símbolo.',
		unidentical: 'las contraseñas no coinciden',
		success: 'Ya puedes iniciar sesión con tus nuevos datos de acceso',
		title: 'Introduce tu nueva contraseña',
		passwordLabel: 'Contraseña',
	},
	notFound: {
		title: 'Oups !',
		description: 'No se ha encontrado la página solicitada',
		backLogin: 'Volver al inicio',
	},
	subUser: {
		returnHome: "Tornar a la pàgina d'inici",
		returnMenu: "Tornar al menú",
		home: {
			title: 'Què desitja fer?',
			newLabel: 'Crear un nou usuari',
			editLabel: 'Gestió d\'usuaris',
		},
		new: {
			title: 'Creació d\'un nou usuari',
			email: 'Introduïu la direcció de correu electrònic del nou usuari',
			services: 'El nou usuari tindrà accés als serveis seleccionats',
			submit: "Crear Usuari",
			success: "S'ha enviat una invitació a la següent adreça",
			side: {
				title: "Un sol compte, diversos usuaris",
				description: 'Ara és possible assignar diversos usuaris al mateix compte de client<br><br>Per tal de poder satisfer sempre les vostres necessitats, podeu afegir o restringir l\'accés de cadascun dels vostres usuaris en qualsevol moment.',
			}
		},
		edit: {
			title: 'Gestió d\'accés d\'usuaris',
			email: 'Seleccioneu l\'usuari per modificar els seus accés',
			services: 'Serveis accessibles per a l\'usuari seleccionat:',
			submit: "Modificar accesos",
			error: "Ho sentim, sembla que encara no ha configurat l'accés a un tercer",
			create: "Crear un nou accés d'usuari",
			success: "Els accessos s'han modificat correctament",
			side: {
				"title": "Modificar els accessos d'usuari",
				"description": "Els vostres empleats evolucionen? Els seus accessos també!<br/><br/>Per tal de poder adaptar-se sempre a les vostres necessitats, podeu afegir o restringir els accessos de cadascun dels vostres usuaris en qualsevol moment."
			}
		},
		verify: {
			error: {
				"400": "Ja heu activat el vostre compte, si us plau, inicieu sessió.",
				"404": "Enllaç no vàlid, torna-ho a provar.",
			}
		},
		activate: {
			valid : {
				title: 'Benvingut!',
				info: "El seu compte s'ha vinculat amb èxit al compte principal",
			},
			waiting: {
				title: 'Introduïu el vostre codi secret',
				info: "S'ha enviat un correu electrònic a la direcció de correu electrònic de l'usuari que va iniciar la sol·licitud de creació del dret d'accés.",
			},
			resend: {
				label: "Reenviar correu electrònic amb el codi",
				success: "La devolució del codi secret s'ha completat correctament",
				error: "S'ha produït un error en retornar el codi secret",
			},
			success: "Felicitats, el vostre vincle s'ha activat amb èxit",
		},
		update: {
			title: "Activació del compte d'usuari",
			password: "La contrasenya ha de complir els següents criteris",
			submit: "Confirmar registre",
			success: "El seu compte s'ha creat correctament. Se l'invitarà a tornar a iniciar sessió.",
			side: {
				"title": "Les informacions indispensables",
				"description": "Per crear un nou compte, és essencial omplir el <b>nom</b> i el <b>cognom</b>, així com establir una <b>contrasenya</b>.",
			},
			error: {
				updated: ""
			}
		},
		logAs: {
			title: "Hola",
			body: "Quin establiment li interessa avui?",
			singleBody: "Estàs connectat a l'establiment :",
			parentLabel: "Llista d'establiments",
			back: "Torna al teu espai",
			services: "Llistes de serveis disponibles",
			submit: "Comencem!",
		}
	}
};
