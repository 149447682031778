export default {
	maintenance: {
		title: 'Servicios en mantenimiento',
		subTitle1:
			'11/03 - 16H : <br/><br/>\
      Debido a un incendio en nuestra host OVH, nuestros servicios están actualmente interrumpidos.<br/><br/>\
      OVH debe realizar un ciertas reparaciones, incluyendo la red eléctrica, que se planificarán a partir de la próxima semana.<br/>\
      Caja registradora -> No se ve afectada<br/><br/>\
      Informes, acceso a datos -> Servicio interrumpido, no accesible<br/>\
      Click & Collect, servicio de mediación -> Servicio interrumpido, no accesible<br/><br/>\
      Como recordatorio, puede acceder al historial de sus servicios desde su caja registradora:<br/><br/>\
      Configuración -> Histórico de la caja.<br/><br/>\
      Gracias por su paciencia e indulgencia.<br/>\
      El equipo de L’Addition',
		subTitle2: ' ',
		altMaintenance: 'mantenimiento',
	},
	tooltip: {
		emailEquals: {
			important: 'Importante',
			identical: 'Este email es idéntico al anterior',
		},
		emailHint: {
			important: 'Importante',
			inputEmail:
				'Introduce un email válido al que tengas acceso (un email de verificación se enviará a esta dirección)',
			notAssociated: "Este email no está asociado a ninguna cuenta L'Addition",
		},
		passwordEquals: {
			important: 'Importante',
			identical: 'Esta contraseña es idéntica a la anterior',
		},
		passwordHint: {
			title: 'Tu contraseña debe incluir:',
			nbCharacters: 'al menos 8 caracteres',
			uppercase: 'al menos 1 mayúscula',
			lowercase: 'al menos 1 minúscula',
			numerical: 'al menos 1 cifra',
			special: 'al menos 1 símbolo',
		},
	},
	header: {
		nameTool: 'Autent',
		nameCompany: "L'Addition",
	},
	errorCode: {
		NOT_SENDING_MAIL: 'Error al enviar el email de verificación',
		UNKNOWN_USER: 'Este email no existe',
		ACCOUNT_NOT_CONFIRMED: 'Tu cuenta no ha sido validada',
		UNKNOWN_TOKEN: 'Enlace inválido, por favor inténtalo de nuevo',
		TOKEN_EXPIRATION: 'Enlace caducado, por favor inténtalo de nuevo',
		UNKNOWN_ERROR: 'Se ha producido un error',
		ACCOUNT_NOT_READY: 'Su cuenta aún no está lista, espere a que su cuenta sea validada por correo electrónico.',
	},
	errorLocalStore:
		'Se ha producido un error durante la autenticación. Por favor, cierra el modo navegación de incógnito o cambia de navegador.',
	errorUnknown: 'Se ha producido un error desconocido, por favor inténtalo de nuevo.',
	wrongService: "Este servicio no pertenece a L'Addition.",
	notAllowed: 'No tienes acceso a este servicio',
	notAllowedPage: 'No tienes acceso a esta página',
	needLogin: 'Necesitas iniciar sesión para acceder a esta página.',
	loginForm: {
		errorIdentifierEmpty: 'Campo requerido',
		errorPasswordEmpty: 'Campo requerido',
		invalidCredentials: 'Usuario o contraseña incorrectos.',
		tooManyWrongRequest:
			'Cuenta bloqueada. Por favor, ponte en contacto con el soporte técnico.',
		loginChanged:
			'Por favor, si has modificado tus datos recientemente, no utilices los anteriores para iniciar sesión en @laddition.com',
		title: 'Autenticación',
		description: 'Identificarme en ',
		emailLabel: 'Usuario/Email',
		passwordLabel: 'Contraseña',
		connection: 'Iniciar sesión',
		forgotPassword: '¿Has olvidado tu contraseña?',
		sponsorship: 'Apadrina a un amigo y recibe 100 €',
		altSponsorship: 'regalo patrocinio',
		successAuthentication: 'Autenticación completada',
	},
	logout: {
		title: 'Cerrando sesión...',
		error: 'Se ha producido un error durante el cierre',
		button: 'Cerrar sesión',
	},
	resetPassword1: {
		empty: 'Campo requerido',
		successVerification:
			'Casi has acabado. En breves instantes, recibirás un email de verificación. Para modificar tu contraseña, haz clic en el botón del email.',
		successSupport:
			'El soporte técnico procesará tu solicitud lo antes posible.',
		notConfirmed: 'Tu cuenta no ha sido validada.',
		noUser: 'Este email no existe.',
		error: 'Se ha producido un error.',
		title: 'Cambia tu contraseña',
		emailLabel: 'Usuario/Email',
		submit: 'Validar',
	},
	resetPassword2: {
		empty: 'Campo requerido',
		errorValidator:
			'Tu contraseña debe contener, al menos, 8 caracteres, 1 mayúscula, 1 minúscula, 1 cifra y 1 símbolo.',
		unidentical: 'las contraseñas no coinciden',
		success: 'Ya puedes iniciar sesión con tus nuevos datos de acceso',
		title: 'Introduce tu nueva contraseña',
		passwordLabel: 'Contraseña',
	},
	notFound: {
		title: 'Oups !',
		description: 'No se ha encontrado la página solicitada',
		backLogin: 'Volver al inicio',
	},
	subUser: {
		returnHome: "Volver a la página de inicio",
		returnMenu: "Volver al menú",
		home: {
			title: '¿Qué desea hacer?',
			newLabel: 'Crear un nuevo usuario',
			editLabel: 'Gestión de usuarios',
		},
		new: {
			title: 'Creación de un nuevo usuario',
			email: 'Ingrese la dirección de correo electrónico del nuevo usuario',
			services: 'El nuevo usuario tendrá acceso a los servicios seleccionados',
			submit: "Crear Usuario",
			success: "Se ha enviado una invitación a la siguiente dirección",
			side: {
				title: 'Un solo cuenta, varios usuarios',
				description: 'Ahora es posible asignar varios usuarios a la misma cuenta de cliente.<br/><br/>Para ello, determine los accesos que autoriza a cada uno de ellos.',
			}
		},
		edit: {
			title: 'Gestión de accesos de usuarios',
			email: 'Seleccione el usuario para modificar sus accesos',
			services: 'Servicios accesibles para el usuario seleccionado:',
			submit: "Modificar accesos",
			error: "Lo sentimos, parece que aún no ha configurado el acceso a un tercero",
			create: "Crear un nuevo acceso de usuario",
			success: "Los accesos se han modificado correctamente",
			side: {
				title: 'Modificar los accesos de los usuarios',
				description: '¿Sus empleados evolucionan? ¡Sus accesos también!<br/><br/>Para poder adaptarse a sus necesidades en todo momento, puede agregar o restringir el acceso de cada uno de sus usuarios en cualquier momento.',
			}
		},
		verify: {
			error: {
				"400": "Ya ha activado su cuenta, por favor inicie sesión.",
				"404": "Enlace inválido, por favor inténtalo de nuevo."
			}
		},
		activate: {
			valid : {
				title: '¡Bienvenido!',
				info: "Su cuenta se ha vinculado con éxito a la cuenta principal",
			},
			waiting: {
				title: 'Ingrese su código secreto',
				info: "Se ha enviado un correo electrónico a la dirección de correo electrónico del usuario que inició la solicitud de creación del derecho de acceso.",
			},
			resend: {
				label: "Reenviar correo electrónico con el código",
				success: "Devolución del código secreto completada con éxito",
				error: "Error al devolver el código secreto",
			},
			success: "Felicidades, su vínculo se ha activado con éxito",
		},
		update: {
			title: "Activación de la cuenta de usuario",
			password: "La contraseña debe cumplir con los siguientes criterios",
			submit: "Confirmar registro",
			success: "Su cuenta se ha creado correctamente. Se le invitará a volver a iniciar sesión.",
			side: {
				title: 'Los informaciones esenciales',
				description: 'Para crear una nueva cuenta, es esencial proporcionar su <strong>nombre</strong> y <strong>apellido</strong>, así como definir una <strong>contraseña</strong>',
			},
			error: {
				updated: "Lo sentimos, parece que ya ha activado su cuenta",
			}
		},
		logAs: {
			title: "Hola",
			body: "¿Qué establecimiento le interesa hoy?",
			singleBody: "Estás conectado al establecimiento :",
			parentLabel: "Lista de establecimientos",
			back: "Regresa a tu espacio",
			services: "Listas de servicios disponibles",
			submit: "¡Empecemos!",
		}
	}
};
