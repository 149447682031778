import { connect } from "react-redux";
import { isAuthenticated, isSubUser } from "../../actions/authActions";
import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Button, Text, Select, Icon, Loader} from "@capps/laddition-storybook";
import { SubUserServices } from "./components/Services";
import axios from "axios";
import { addFlashMessage } from "../../../flashMessage/actions/flashMessages";
import {I18n, Translate} from "react-redux-i18n";
import {motion, useAnimate} from "framer-motion";
import {SubUserForm} from "./components/SubUserForm";
import {transformErrorMessage} from "../../../utils/errorMessage";

function EditSubUser ({ isAuthenticated, addFlashMessage, isSubUser }) {

    const navigate = useNavigate();
    if (!isAuthenticated() || isSubUser()) {
        navigate('/');
        return <div></div>;
    }

    const [servicesList, setServices] = useState([]);
    const [subUsersList, setSubUsers] = useState([]);
    const [servicesActiveList, setActiveServices] = useState([]);
    const [subUserId, setSubUser] = useState(0);
    const [activeSubUser, setActiveSubUser] = useState({});
    const [scope, animate] = useAnimate();
    const [sidebarScope, animateSidebar] = useAnimate();
    const [isLoading, setLoading] = useState(true);

    const [notFoundScope, animateNotFound] = useAnimate();

    const screenWidth = useRef(window.innerWidth);
    const isTabletPortrait = screenWidth.current <= 992;
    const formExitAnimation = isTabletPortrait ? { y: "100%" } : { x: "100%" };

    useEffect(() => {
        async function fetchAllData() {
            await getSubUserList();
            await getServicesUser();
        }
        fetchAllData().finally(() => setLoading(false));
    }, []);


    const getSubUserList = () => {
        return axios.get('/v2/api/sub-user').then((response) => {
            const results = response.data.result;
            setSubUsers(results);

        }).catch(() => navigate("/logout"));
    }
    const getServicesUser = () => {
        return axios.get('/v2/api/sub-user/services/list').then((response) => {
            const services = response.data.services;
            setServices(services.filter((s) => !s.hidden));
        })
    }

    const getActiveSubUserServices = () => {
        return axios.get('/v2/api/sub-user/services', {params: { parentUserId: activeSubUser.id }})
            // .catch(() => browserHistory.push("/logout"));
    }

    useEffect(() => {
        if (!!activeSubUser.id) {
            getActiveSubUserServices().then((response) => {
                const activeServicesIds = response.data.services.map((service) => service.id);
                setActiveServices(activeServicesIds);
            });
        }
    }, [activeSubUser.id])
    const updateList = (activeList) => setActiveServices(activeList)

    const submitForm = () => {
        const body = {
            services: servicesActiveList,
            sub_user_id: activeSubUser.sub_user_id,
        };
        return axios.put("/v2/api/sub-user/rights", body)
            .then((response) => {
                addFlashMessage({
                    type: "success",
                    text: I18n.t('subUser.edit.success'),
                });
                backToMenu();
            })
            .catch((error) => {
                addFlashMessage({
                    type: 'error',
                    text: error.response.data.error.message,
                });
            })
    }

    const resendCreationMail = () => {
        return axios.get("/v2/api/sub-user/resend", { params: { sub_user_id: activeSubUser.sub_user_id } })
          .then(() => {
            addFlashMessage({
              type: "success",
              text: I18n.t('subUser.edit.resend.success'),
            });
          })
          .catch((error) => {
              const { code, message } = error.response.data;
              const errorCode = `subUser.errorCode.${code}`;
              const text = code ? transformErrorMessage(errorCode, message) : I18n.t('subUser.edit.resend.error');
              addFlashMessage({ type: 'error', text });
          });
    }

    const navigateToNewPage = async () => {
        animateSidebar(sidebarScope.current, { opacity: 0 }, { duration: 0.3 });
        await animateNotFound(notFoundScope.current, { opacity: 0 }, { duration: 0.3 });

        navigate('/sub-user/new');
    }

    const backToMenu = async () =>  {
        animateSidebar(sidebarScope.current, { opacity: 0 }, { duration: 0.3 });
        await animate(scope.current, formExitAnimation, { duration: 0.3 });
        navigate('/sub-user');
    }

    const useOptGroup = subUsersList.some((subUser) => subUser.enabled === 0);

    const pageContent = subUsersList.length ?
        <SubUserForm
            backOnClick={backToMenu}
            sideTitle="subUser.edit.side.title"
            sideDescription="subUser.edit.side.description"
            animateRef={scope}
            sidebarAnimateRef={sidebarScope}
            formTitle="subUser.edit.title"
        >
            <div className="sub-user-form">
                <Text type="info" className="ml-5" >{I18n.t('subUser.edit.email')} :</Text>
                <div className="flex w-100 align-center" style={{gap: "20px", flexWrap: "wrap"}}>
                    <div className="sub-user-form-input">
                        <Select
                          aria-label="add HTML native attributes"
                          label="Adresse Mail"
                          onChange={(subUserId) => setActiveSubUser(subUsersList.find((subUser) => subUser.id === +subUserId))}
                        >
                            { useOptGroup && <>
                                <optgroup label="Compte Activé">
                                    {subUsersList.map((subuser) => subuser.enabled &&
                                      <option value={subuser.id}>{subuser.email}</option>)}
                                </optgroup>
                                <optgroup label="En attente">
                                    {subUsersList.map((subuser) => subuser.enabled === 0 &&
                                      <option value={subuser.id}>{subuser.email}</option>)}
                                </optgroup>
                            </> }
                            { !useOptGroup && <>{subUsersList.map((subuser) => <option value={subuser.id}>{subuser.email}</option>)}</>}
                        </Select>
                    </div>
                    {
                        activeSubUser.id &&
                        activeSubUser.enabled === 0 &&
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={resendCreationMail}
                            color="main"
                        >
                          Renvoyer mail
                        </Button>
                    }
                </div>
                <div className="divider"></div>
                <div className={!activeSubUser.id || activeSubUser.enabled === 0 ? "disabled" : ""}>
                    <Text className="mb-5">{I18n.t('subUser.edit.services')}</Text>
                    {
                        servicesList.length > 0 ?
                          <SubUserServices key={servicesActiveList} servicesList={servicesList}
                                           activeServices={servicesActiveList} onClick={updateList}
                                           subUserId={activeSubUser.id}/>
                          : null
                    }
                    <div className="sub-user-form-buttons w-100">
                        <Button color="primary" label={I18n.t('subUser.edit.submit')} onClick={submitForm} />
                    </div>
                </div>
            </div>
        </SubUserForm>
        :
        <div ref={notFoundScope} className="container__form py-20">
            <Text color="white" variant="h2" className="title">{I18n.t('subUser.edit.error')}</Text>
            <div className="sub-user-form-buttons sb-mx-auto">
                <Button label={I18n.t('subUser.returnMenu')} onClick={() => navigate('/sub-user') } className="w-100 mb-10" />
                <Button color="secondary" label={I18n.t('subUser.edit.create')} onClick={navigateToNewPage} className="w-100 mb-10" />
            </div>
        </div>;

    return <>
        { !isLoading && pageContent }
        { isLoading &&
            <div className="loader__background">
                <Loader />
            </div>
        }
    </>

}

export default connect(null, { isAuthenticated, addFlashMessage, isSubUser })(EditSubUser);