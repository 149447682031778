import React, {useEffect} from "react";
import { Button, Text } from "@capps/laddition-storybook";

import "../../../css/subuser.css";
import { connect } from "react-redux";
import { isSubUser, isAuthenticated } from "../../actions/authActions";
import { useNavigate } from "react-router-dom";
import { I18n } from 'react-redux-i18n';
import { useAnimate } from "framer-motion";
import { addFlashMessage } from "../../../flashMessage/actions/flashMessages";

function SubUserHome({ isSubUser, addFlashMessage }) {
    const navigate = useNavigate();

    if (!isAuthenticated()) {
        navigate('/');
        return <></>;
    }

    const [scope, animate] = useAnimate();

    const goTo = async (path) => {
        await animate(scope.current, { opacity: 0 }, { duration: 0.3 });
        navigate(path);
    }

    return <div ref={scope} className="sub-user-home align-center container__form">
        { !isSubUser() &&
            <>
                <Text className="sub-user-home-title text-center" variant="h2" color="white">{I18n.t('subUser.home.title')}</Text>
                <Button
                    label={I18n.t('subUser.home.newLabel')}
                    color="secondary"
                    onClick={() => goTo('/sub-user/new')}
                />
                <Button
                    label={I18n.t('subUser.home.editLabel')}
                    color="white"
                    className="text-secondary"i
                    onClick={() => goTo('/sub-user/edit')}
                />
            </>
        }
        { isSubUser() && <Text className="sub-user-home-title text-center" variant="h2" color="white">{I18n.t('notAllowedPage')}</Text> }
        <Button color={isSubUser() ? 'secondary' : 'ldd-grey-100'} variant={isSubUser() ? "contained" : "text"} className="sub-user-home-back mb-0" onClick={() => goTo("/")}>Retour sur votre espace</Button>
    </div>
}

export default connect(null, { isSubUser, addFlashMessage, isAuthenticated })(SubUserHome);